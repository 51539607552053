import React, { Component } from "react";
import { css } from "emotion";
import { CFText } from "components";
import { cflogo2, footer } from "images";
import Navbar from "./Navbar";
import { MEDIA_QUERY } from "styles";

export default class PageFooter extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <img className={styles.image} src={cflogo2} alt="CodeFusion" />
          <Navbar />
        </div>
        <CFText content dark className={styles.copyright}>
          © 2020 CodeFusion Inc.
        </CFText>
      </div>
    );
  }
}

const styles = {
  container: css({
    marginTop: "-2rem",
    position: "relative",
    width: "100%",
    background: `url(${footer}) no-repeat`,
    backgroundSize: "cover",
    padding: "4rem",
    display: "flex",
    justifyContent: "center",
    [MEDIA_QUERY.MD]: {
      padding: "2rem 2rem 8rem 2rem",
    },
  }),
  innerContainer: css({
    width: "100%",
    maxWidth: "110rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [MEDIA_QUERY.MD]: {
      marginBottom: "1rem",
    },
  }),
  image: css({
    width: "20rem",
    objectFit: "contain",
    [MEDIA_QUERY.MD]: {
      width: "14rem",
    },
  }),
  copyright: css({
    position: "absolute",
    bottom: "2rem",
    right: "9rem",
  }),
};
