import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { CFText } from "components";
import { css } from "emotion";
import cn from "classnames";
import { MEDIA_QUERY } from "styles";

class Navbar extends Component {
  render() {
    const { className } = this.props;
    const classNames = cn(styles.container, className);
    return (
      <nav className={classNames}>
        <NavLink className={styles.link} to="/about">
          <CFText tech h3 link>
            ABOUT
          </CFText>
        </NavLink>
        <a href="mailto:hello@codefusion.tech" className={styles.link}>
          <CFText tech h3 link>
            CONTACT US
          </CFText>
        </a>
        <NavLink className={styles.link} to="/terms">
          <CFText tech h3 link>
            TERMS
          </CFText>
        </NavLink>
        <NavLink className={styles.link} to="/privacy">
          <CFText tech h3 link>
            PRIVACY
          </CFText>
        </NavLink>
      </nav>
    );
  }
}

const styles = {
  container: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: "10rem",
    width: "50%",
    [MEDIA_QUERY.MD]: {
      flexDirection: "column",
      width: "80%",
      alignItems: "flex-end"
    }
  }),
  link: css({
    textDecoration: "none",
    transition: "all .2s",
    color: "black",
    "&:hover": {
      transform: "scale(1.05)",
      color: "#36B7EE"
    }
  })
};

export default withRouter(Navbar);
