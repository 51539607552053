import React, { Component } from "react";
import { css } from "emotion";
import { CFView, CFText, Tech } from "components";
import { manage, mobile, web, techbg } from "images";
import { MEDIA_QUERY } from "styles";

export default class Technology extends Component {
  render() {
    return (
      <CFView className={styles.container}>
        <div className={styles.innerContainer}>
          <Tech className={styles.devices} />
          <CFView column className={styles.tech}>
            <div style={{ lineHeight: 1 }}>
              <CFText tech dark h1 bold>
                Your partnership includes:
              </CFText>
            </div>
            <CFView h="4rem" />
            <CFView className={styles.row}>
              <img src={web} alt="Website" className={styles.image} />
              <CFView column mh="2rem">
                <CFText content primary bold h2>
                  Modern Web App
                </CFText>
                <CFText content dark h4>
                  Let customers order directly from your stunning, personalized
                  website.
                </CFText>
              </CFView>
            </CFView>
            <CFView className={styles.row}>
              <img src={mobile} alt="Mobile App" className={styles.image} />
              <CFView column mh="2rem">
                <CFText content primary bold h2>
                  Advanced Mobile App
                </CFText>
                <CFText content dark h4>
                  Your custom mobile app showcases your business on both iOS and
                  Android.
                </CFText>
              </CFView>
            </CFView>
            <CFView className={styles.row}>
              <img
                src={manage}
                alt="Order Management Tablet"
                className={styles.image}
              />
              <CFView column mh="2rem">
                <CFText content primary bold h2>
                  Order Management Tablet
                </CFText>
                <CFText content dark h4>
                  Manage orders through your tablet. Conveniently update your
                  menu on the fly.
                </CFText>
              </CFView>
            </CFView>
          </CFView>
        </div>
      </CFView>
    );
  }
}

const styles = {
  container: css({
    padding: "8rem 4rem 8rem 4rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: `url(${techbg}) no-repeat`,
    backgroundSize: "cover",
    width: "100%",
    zIndex: 99,
    boxShadow: "0.4rem -0.4rem 0.4rem rgba(0,0,0,0.6)",
    [MEDIA_QUERY.LG]: {
      padding: "8rem 2rem 8rem 2rem",
      justifyContent: "flex-start",
      textAlign: "center",
      backgroundSize: "auto 100%",
    },
  }),
  innerContainer: css({
    display: "flex",
    width: "100%",
    maxWidth: "120rem",
    [MEDIA_QUERY.LG]: {
      flexDirection: "column",
      textAlign: "center",
    },
  }),
  tech: css({
    marginLeft: "8rem",
    marginTop: "-1rem",
    [MEDIA_QUERY.LG]: {
      marginLeft: 0,
      marginTop: 0,
    },
  }),
  row: css({
    display: "flex",
    alignItems: "center",
    marginBottom: "2rem",
    [MEDIA_QUERY.LG]: {
      flexDirection: "column",
    },
  }),
  image: css({
    width: "10rem",
    objectFit: "contain",
  }),
  devices: css({
    [MEDIA_QUERY.LG]: {
      maxHeight: "40rem",
    },
    [MEDIA_QUERY.SM]: {
      maxHeight: "32rem",
    },
  }),
};
