import React, { Component } from "react";
import { CFLink, CFText, CFView } from "components";
import {
  applause,
  chihouse,
  ebisu,
  ebisumobile,
  ginza,
  hanayuki,
  hokepoke,
  hyack,
  jamesonhastings,
  kamei,
  kaori,
  kiku,
  kitstaya,
  meadowsushi,
  mrho,
  onemoreszechuan,
  onon,
  partnersbg,
  ryuu,
  sakemaki,
  shaolin,
  tacofactory,
  tomsushi
} from "images";
import { MEDIA_QUERY } from "styles";
import { css } from "emotion";
import Slider from "react-slick";
import { MediaQuery } from "react-responsive";

const settings = {
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  cssEase: "linear",
  pauseOnHover: false,
  draggable: true,
  swipe: true
};

export default class Customers extends Component {
  render() {
    return (
      <CFView className={styles.container}>
        <CFText tech h1 dark center bold>
          Our Partners
        </CFText>
        <CFView className={styles.restaurantContainer}>
          <img className={styles.mobileApp} src={ebisumobile} alt="Ebisu App" />
        </CFView>
        <CFView className={styles.logos}>
          <MediaQuery maxWidth={767}>
            {matches => {
              if (matches) {
                return (
                  <Slider {...{ ...settings, slidesToShow: 2 }}>
                    <CFLink href="https://robson.ebisu.ca">
                      <img
                        src={ebisu}
                        alt="Ebisu"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://hyacksushi.com">
                      <img
                        src={hyack}
                        alt="Hyack Sushi"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://tomsushi.ca">
                      <img
                        src={tomsushi}
                        alt="Tom Sushi"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://ryuuorder.com">
                      <img
                        src={ryuu}
                        alt="Ryuu"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://sake-maki.ca/">
                      <img
                        src={sakemaki}
                        alt="Sake Maki"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://ginza-poco.ca">
                      <img
                        src={ginza}
                        alt="Ginza Sushi"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://www.ononwontonhouse.com">
                      <img
                        src={onon}
                        alt="On On Wonton House"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://www.mrhowontonhouse.com">
                      <img
                        src={mrho}
                        alt="Mr Ho Wonton House"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://applauserestaurant.ca">
                      <img
                        src={applause}
                        alt="Applause Sushi"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://shaolinhouse.ca">
                      <img
                        src={shaolin}
                        alt="Shaolin House"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://kameibaru.ca">
                      <img
                        src={kamei}
                        alt="Kamei Baru"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://chihouse.ca">
                      <img
                        src={chihouse}
                        alt="Chi House"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://kitstayasushi.ca">
                      <img
                        src={kitstaya}
                        alt="Kitstaya Sushi"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://kaoriizakaya.com">
                      <img
                        src={kaori}
                        alt="Kaori Izakaya"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://www.hanayukirestaurant.com">
                      <img
                        src={hanayuki}
                        alt="Hanayuki Sushi"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://kikusushirestaurant.ca">
                      <img
                        src={kiku}
                        alt="Kiku Sushi"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://meadowsushi.com">
                      <img
                        src={meadowsushi}
                        alt="Meadow Sushi"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://www.onemoreszechuan.ca">
                      <img
                        src={onemoreszechuan}
                        alt="One More Szechuan"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://tacofactoryorder.com">
                      <img
                        src={tacofactory}
                        alt="The Taco Factory"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://thehokepoke.ca">
                      <img
                        src={hokepoke}
                        alt="Hoke Poke"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://jamesonhastings.com">
                      <img
                        src={jamesonhastings}
                        alt="James on Hastings"
                        className={styles.restaurants}
                      />
                    </CFLink>
                  </Slider>
                );
              } else {
                return (
                  <Slider {...{ ...settings, slidesToShow: 4 }}>
                    <CFLink href="https://robson.ebisu.ca">
                      <img
                        src={ebisu}
                        alt="Ebisu"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://hyacksushi.com">
                      <img
                        src={hyack}
                        alt="Hyack Sushi"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://tomsushi.ca">
                      <img
                        src={tomsushi}
                        alt="Tom Sushi"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://ryuuorder.com">
                      <img
                        src={ryuu}
                        alt="Ryuu"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://sake-maki.ca/">
                      <img
                        src={sakemaki}
                        alt="Sake Maki"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://ginza-poco.ca">
                      <img
                        src={ginza}
                        alt="Ginza Sushi"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://www.ononwontonhouse.com">
                      <img
                        src={onon}
                        alt="On On Wonton House"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://www.mrhowontonhouse.com">
                      <img
                        src={mrho}
                        alt="Mr Ho Wonton House"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://applauserestaurant.ca">
                      <img
                        src={applause}
                        alt="Applause Sushi"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://shaolinhouse.ca">
                      <img
                        src={shaolin}
                        alt="Shaolin House"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://kameibaru.ca">
                      <img
                        src={kamei}
                        alt="Kamei Baru"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://chihouse.ca">
                      <img
                        src={chihouse}
                        alt="Chi House"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://kitstayasushi.ca">
                      <img
                        src={kitstaya}
                        alt="Kitstaya Sushi"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://kaoriizakaya.com">
                      <img
                        src={kaori}
                        alt="Kaori Izakaya"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://www.hanayukirestaurant.com">
                      <img
                        src={hanayuki}
                        alt="Hanayuki Sushi"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://kikusushirestaurant.ca">
                      <img
                        src={kiku}
                        alt="Kiku Sushi"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://meadowsushi.com">
                      <img
                        src={meadowsushi}
                        alt="Meadow Sushi"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://www.onemoreszechuan.ca">
                      <img
                        src={onemoreszechuan}
                        alt="One More Szechuan"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://tacofactoryorder.com">
                      <img
                        src={tacofactory}
                        alt="The Taco Factory"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://thehokepoke.ca">
                      <img
                        src={hokepoke}
                        alt="Hoke Poke"
                        className={styles.restaurants}
                      />
                    </CFLink>
                    <CFLink href="https://jamesonhastings.com">
                      <img
                        src={jamesonhastings}
                        alt="James on Hastings"
                        className={styles.restaurants}
                      />
                    </CFLink>
                  </Slider>
                );
              }
            }}
          </MediaQuery>
        </CFView>
      </CFView>
    );
  }
}

const styles = {
  container: css({
    padding: "2rem",
    marginTop: "-6rem",
    height: "80rem",
    background: `url(${partnersbg}) no-repeat`,
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    zIndex: 1,
    position: "relative",
    textAlign: "center",
    [MEDIA_QUERY.MD]: {
      backgroundSize: "auto 100%",
      padding: "2rem"
    }
  }),
  restaurantContainer: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10rem",
    marginLeft: "3rem",
    [MEDIA_QUERY.MD]: {
      marginTop: "14rem"
    }
  }),
  restaurants: css({
    width: "22rem",
    objectFit: "contain",
    transition: "all .2s",
    "&:hover": {
      transform: "scale(1.02)"
    },
    [MEDIA_QUERY.MD]: {
      width: "100%"
    }
  }),
  logos: css({
    position: "absolute",
    bottom: "0.2rem",
    height: "17rem",
    width: "90rem",
    left: "50%",
    transform: "translateX(-50%)",
    [MEDIA_QUERY.MD]: {
      width: "100%",
      bottom: "-.5rem"
    }
  }),
  button: css({
    [MEDIA_QUERY.MD]: {
      width: "25vw"
    }
  }),
  mobileApp: css({
    objectFit: "contain",
    width: "32rem",
    [MEDIA_QUERY.MD]: {
      width: "100%"
    }
  })
};
