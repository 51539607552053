import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import * as pages from "pages";

export default class Routes extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" exact component={pages.Home} />
          <Route path="/about" component={pages.About} />
          <Route path="/privacy" component={pages.Privacy} />
          <Route path="/terms" component={pages.Terms} />
          <Route component={pages.Page404} />
        </Switch>
      </Router>
    );
  }
}
