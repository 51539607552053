import React, { Component } from "react";
import { css } from "emotion";
import { cfvideo } from "images";
import { CFText, CFView } from "components";

export default class About extends Component {
  render() {
    return (
      <CFView h="100vh" w="100vw" column justifyCenter alignCenter>
        <video autoPlay muted loop className={styles.logo}>
          <source src={cfvideo} />
        </video>
        <CFText content h4 bold dark center>
          Our mission is to bring value to the community through technology.
        </CFText>
        <CFText content h4 bold dark center>
          Proudly based in Vancouver, BC.
        </CFText>
      </CFView>
    );
  }
}

const styles = {
  logo: css({
    height: "80%",
    width: "100%",
    objectFit: "contain"
  })
};
