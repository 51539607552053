import React, { Component } from "react";
import { css } from "emotion";
import cn from "classnames";
import { CFText } from "components";

export default class PrimaryButton extends Component {
  render() {
    const { className, label, ...props } = this.props;
    const classNames = cn(styles.button, className);
    return (
      <button className={classNames} {...props}>
        <CFText tech h4 white bold center>
          {label}
        </CFText>
      </button>
    );
  }
}

const styles = {
  button: css({
    paddingLeft: "4rem",
    paddingRight: "4rem",
    height: "5rem",
    background: "#36B7EE",
    borderRadius: "0.8rem",
    boxShadow: "0 .2rem .2rem rgba(0,0,0,0.6)",
    cursor: "pointer",
    transition: "all .2s",
    "&:hover": {
      transform: "scale(1.03)"
    }
  })
};
