import React, { Component } from "react";
import { CFView, PageHeader, PageFooter } from "components";
import Hero from "./Hero";
import Technology from "./Technology";
import Features from "./Features";
import ValueProp from "./ValueProp";
import Customers from "./Customers";

export default class Home extends Component {
  render() {
    return (
      <CFView w="100%" column>
        <PageHeader />
        <Hero />
        <Technology />
        <Features />
        <ValueProp />
        <Customers />
        <PageFooter />
      </CFView>
    );
  }
}
