import React, { Component } from "react";
import { css } from "emotion";
import { CFView, CFText } from "components";
import { valueprop1, valueprop2, valueprop3, techbg } from "images";
import { MEDIA_QUERY } from "styles";

export default class ValueProp extends Component {
  render() {
    return (
      <CFView w="100%" row justifyCenter className={styles.container}>
        <CFView column w="100rem" pt="6rem">
          <CFText tech h1 dark center bold>
            Why CodeFusion?
          </CFText>
          <div className={styles.row}>
            <CFView column alignCenter w="30rem">
              <img
                src={valueprop1}
                alt="Increase Sales"
                className={styles.image}
              />
              <CFView h1 />
              <CFText content h2 primary bold center>
                Increase Sales
              </CFText>
              <CFView h1 />
              <CFText h6 dark center>
                CodeFusion is designed to engage online customers. Our loyalty
                program brings customers back for more.
              </CFText>
            </CFView>
            <CFView h="4rem" />
            <CFView column alignCenter w="30rem">
              <img
                src={valueprop2}
                alt="Reduce Inefficiency"
                className={styles.image}
              />
              <CFView h1 />
              <CFText content h2 primary bold center>
                Reduce Inefficiency
              </CFText>
              <CFView h1 />
              <CFText h6 dark center>
                Increase revenue by speeding up your operations. Remove the
                bottlenecks in your business.
              </CFText>
            </CFView>
            <CFView h="4rem" />
            <CFView column alignCenter w="30rem">
              <img
                src={valueprop3}
                alt="Business Insights"
                className={styles.image}
              />
              <CFView h1 />
              <CFText content h2 primary bold center>
                Business Insights
              </CFText>
              <CFView h1 />
              <CFText h6 dark center>
                Access valuable data about your orders. Learn about your
                customers and their preferences.
              </CFText>
            </CFView>
          </div>
        </CFView>
      </CFView>
    );
  }
}

const styles = {
  container: css({
    padding: "4rem 4rem 16rem 4rem",
    background: `url(${techbg}) no-repeat`,
    backgroundSize: "cover",
    [MEDIA_QUERY.MD]: {
      paddingBottom: "12rem",
    },
  }),
  image: css({
    objectFit: "contain",
    width: "22rem",
    height: "22rem",
  }),
  row: css({
    marginTop: "8rem",
    display: "flex",
    justifyContent: "space-between",
    [MEDIA_QUERY.MD]: {
      marginTop: "2rem",
      flexDirection: "column",
      alignItems: "center",
    },
  }),
};
