import React, { Component } from "react";
import { css } from "emotion";
import { technology } from "images";
import cn from "classnames";

export default class Tech extends Component {
  render() {
    const { className } = this.props;
    const classNames = cn(styles.image, className);
    return (
      <img
        className={classNames}
        src={technology}
        alt="The Ordering Platform"
      />
    );
  }
}

const styles = {
  image: css({
    objectFit: "contain"
  })
};
