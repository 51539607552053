import React, { Component } from "react";
import { css } from "emotion";

export default class Terms extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <h1>Terms of Use</h1>
          <p>CODEFUSION INC.</p>
          <p>
            CODEFUSION INC. (CODEFUSION/“WE”/”US”) OWNS AND OPERATES THE ONLINE
            ORDERING PLATFORM WHICH INCLUDES WEBSITES AND MOBILE APPLICATIONS.
            THESE TERMS OF USE APPLY TO ALL USERS OF THE ORDERING PLATFORM,
            INCLUDING USERS WHO UPLOAD ANY MATERIALS TO THE ORDERING PLATFORM,
            USERS WHO USE SERVICES PROVIDED THROUGH THIS ORDERING PLATFORM,
            USERS WHO DOWNLOAD THE CODEFUSION APP AND USERS WHO SIMPLY VIEW THE
            CONTENT ON OR AVAILABLE THROUGH THIS ORDERING PLATFORM (“USERS” /
            “YOU”).
          </p>
          <p>
            BY USING THE ORDERING PLATFORM, SERVICE AND/OR THE CODEFUSION APP
            YOU INDICATE YOUR ACCEPTANCE OF THESE TERMS OF USE. IF YOU DO NOT
            ACCEPT THESE TERMS OF USE, THEN DO NOT USE THE CODEFUSION APP OR
            THIS ORDERING PLATFORM OR ANY OF ITS CONTENT OR SERVICES. THESE
            TERMS OF USE MAY BE AMENDED OR UPDATED BY CODEFUSION FROM TIME TO
            TIME WITHOUT NOTICE AND THE TERMS OF USE MAY HAVE CHANGED SINCE YOUR
            LAST VISIT TO THIS ORDERING PLATFORM. IT IS YOUR RESPONSIBILITY TO
            REVIEW THESE TERMS OF USE FOR ANY CHANGES. YOUR USE AFTER ANY
            AMENDMENTS OR UPDATES OF THESE TERMS OF USE SHALL SIGNIFY YOUR
            ASSENT TO AND ACCEPTANCE OF SUCH REVISED TERMS. ANY NEW FEATURES
            THAT MAY BE ADDED TO THIS ORDERING PLATFORM FROM TIME TO TIME WILL
            BE SUBJECT TO THESE TERMS OF USE, UNLESS STATED OTHERWISE. YOU
            SHOULD VISIT THIS PAGE PERIODICALLY TO REVIEW THESE TERMS OF USE.
          </p>
          <p>
            IN ORDER TO USE THE APP, YOU WILL BE REQUIRED TO CREATE AN ACCOUNT
            AND PROVIDE CERTAIN INFORMATION, INCLUDING PERSONAL INFORMATION. ALL
            PERSONAL INFORMATION THAT YOU PROVIDE WILL BE TREATED IN ACCORDANCE
            WITH THE TERMS OF OUR PRIVACY POLICY, WHICH IS INCORPORATED BY
            REFERENCE INTO THESE TERMS. IF YOU DO NOT AGREE WITH THE TERMS OF
            OUR PRIVACY POLICY, DO NOT DOWNLOAD, INSTALL OR OTHERWISE USE THE
            APP, ORDERING PLATFORM OR SERVICES.
          </p>
          <p>
            THESE TERMS OF USE CONTAIN IMPORTANT TERMS ABOUT YOUR USE OF THE
            SERVICES, INCLUDING LIMITATIONS OF OUR LIABILITY FOR YOUR USE OF THE
            SERVICE. PLEASE TAKE A FEW MOMENTS TO READ THESE TERMS FROM START TO
            FINISH BEFORE USING THE APP AND/OR SERVICE.
          </p>
          <p>
            The Service. The CodeFusion service has two features. First, it
            allows Users to order and pay for take out (the “Takeout Service”)
            and second, it allows Users to dine in and pay for self-service
            orders (the “Dine In Service”) at restaurants that use our services
            (“Restaurants”) directly from your mobile device. For Takeout, the
            service will notify you, via your mobile device, when your order is
            ready and all you have to do is collect your meal from the
            restaurant. For Dine In, you may view Restaurants’ menus and place
            orders on the App, when you are done your meal you can pay on your
            mobile device or to the Restaurant in cash. Users can also rate the
            Restaurants in the App. You must turn location services on to use
            the App and see nearby Restaurants (the “Service”).
          </p>
          <p>
            FOR GREATER CLARITY CODEFUSION IS NOT A RESTAURANT AND WILL NOT BE
            FULFILLING THE ORDERS. CODEFUSION ACTS AS AN INTERMEDIARY BETWEEN
            THE RESTAURANTS AND THE USER. THE PROVISION OF THE TAKE OUT OR DINE
            IN ORDER IS SUBJECT TO AN AGREEMENT ENTERED INTO BETWEEN YOU AND THE
            RESTAURANT, TO WHICH CODEFUSION IS NOT A PARTY. CODEFUSION DO NOT
            CONTROL AND CANNOT BE RESPONSIBLE FOR THE CONDUCT OF THE
            RESTAURANTS. CODEFUSION DISCLAIMS ALL LIABILITY FOR ANY OF THE FOOD
            PREPARED OR ANY ACT OR OMISSION BY THE RESTAURANTS. SEE “DISCLAIMER
            OF REPRESENTATIONS, WARRANTIES AND CONDITIONS” AND “LIMITATION OF
            LIABILITY” BELOW FOR MORE INFORMATION.
          </p>
          <p>
            2. User Accounts. In order to access the Service, you must have a
            valid mobile number, be a registered user and have downloaded the
            CodeFusion App to a personal mobile device. You can register for an
            account either by logging in through your Google account or
            Facebook, or by creating a manual account by providing your email
            address, name and password. If you login through Facebook, we will
            access and collect information from your public profile, including
            your name, profile image and email address. If you log-in through
            your Google account, we will collect your email addresses, your
            public profile and name. If you create a manual account, in addition
            to providing your name and email address you will be required to
            provide a password. All activity that occurs on your account is your
            sole and exclusive responsibility and we will not be liable for any
            activity occurring on your account, including without limitation
            unauthorized activity by a third party. Accordingly, please choose a
            strong password and do not share it with any other person. If you
            discover or suspect your account has been compromised, please notify
            us immediately.
          </p>
          <p>
            3. User Profiles. Once you have registered for the Service, you will
            be able to create a user profile. In order to provide the Services
            to you, we require your phone number. In addition, you can include
            some, all or none of the following information in your profile,
            profile picture and email address. If you want to place orders via
            the App we will also require payment information such as a valid
            credit card.
          </p>
          <p>
            4. Dietary Notes. You can list dietary restrictions and notes on
            your user profile. Each time you place a Takeout or Dine In order
            your dietary restrictions will accompany the order to the
            Restaurant.
          </p>
          <p>
            5. Device Permissions. To use the Service, you must allow the
            CodeFusion App to send push notifications and access your location
            services on your personal mobile device. If you want to host a
            party, as further described in Placing Dine In Orders below, you
            must share your contacts on your mobile device with CodeFusion.
          </p>
          <p>
            6. Placing Takeout Orders. To place a Takeout order, simply open the
            CodeFusion App and select the Restaurant. You will see the
            Restaurant menu and offerings (“Menu”) and you can place an order
            from the Menu and pay for the product you have purchased. When you
            place an order the Restaurant will also receive any dietary notes.
            Upon payment you will receive a receipt for your order from the
            Restaurant. When your order is ready, the Restaurant will notify you
            via your mobile device, and you can go collect your order.
          </p>
          <p>
            7. Placing Dine In Orders. When you arrive at the Restaurant, one
            user will scan a QR Code (“Check In”) and the party will take a
            table. If there are multiple people in your party, the person who
            Checked In will host the table (the “Host”) and the other users will
            be guests of the Host (the “Guests”). Provided you have shared your
            contacts with the App, as the Host you can invite Guests to join
            your party via the App. The Host and the Guests place their orders
            on the App. When you place an order the Restaurant will also receive
            any dietary notes for both the Host and the Guests, if any. If you
            order alcohol, the Restaurant may verify your age before serving
            you. When your order is ready, the Restaurant brings your order to
            your table. When you are done dining, you can pay for your order on
            the App or in cash. The Host can pay for the entire order or split
            it up amongst the Host and the Guests. The Host is ultimately
            responsible for the bill and if the Host fails to pay for the order,
            CodeFusion will share the Host’s contact information with the
            Restaurant with a view to settling the bill.
          </p>
          <p>
            8. Changing Orders. If you need to change a “Takeout” order after
            placing it, you will have to coordinate directly with the
            Restaurant. After a “Takeout” order has been placed and paid for it
            is not possible to cancel it. If you need to change a “Dine in”
            order, you may coordinate directly with the Restaurant attendant.
          </p>
          <p>
            9. Rating Restaurants. You can rate Restaurants on the App. When you
            give a rating, your user name, star rating and comments will be
            visible by all Users of the Service on the Restaurant page.
          </p>
          <p>
            10. Payments. In order to make in-App purchases, you must provide
            valid credit card information to the App. All payment information is
            submitted via a secure connection to our payment processor, Moneris.
            Accordingly, the use of your credit card information and other
            payment information is governed by the terms of the Moneris Terms of
            Use and Moneris Privacy Policy which you should review. All payments
            must be made at the time you place your “Takeout” order or at the
            end of your Dine In experience. Once a payment has been processed
            you will receive a text message on your mobile device.
          </p>
          <p>
            11. No Refunds. In general it is not the CodeFusion policy to
            provide refunds if you are dissatisfied with your order. We may, in
            our sole discretion provide a refund in certain circumstances,
            provided that the Restaurant also agrees to the refund.
          </p>
          <p>
            12. Taxes. The prices for the goods listed on the Ordering Platform
            are exclusive of tax. Accordingly, Users are responsible for the
            payment of all taxes applicable to the purchases made via the App,
            including, without limitation, provincial sales taxes and value
            added taxes and any other applicable local, municipal, provincial or
            federal taxes, tariffs, levies or duties payable in respect of the
            products purchased via the App.
          </p>
          <p>
            13. Fraudulent Transactions. We have the right to suspend the
            processing of any transaction where we reasonably believe that the
            transaction may be fraudulent, illegal or involves any criminal
            activity. If You challenge a transaction or payment, You agree that
            You will assist us in complying with any laws and card or financial
            institution rules or policies
          </p>
          <p>
            14. Content. All information, data, text, software, music, sound,
            photographs, graphics, video, messages or other materials, whether
            publicly posted or privately transmitted to the Ordering Platform by
            viewers or users whether in forums or otherwise (“User Content”), is
            the sole responsibility of such viewers or users. This means that
            the viewer or user, and not CodeFusion, are entirely responsible for
            all such material uploaded, posted, emailed, transmitted or
            otherwise made available by using the Service. CodeFusion does not
            control or actively monitor User Content and, as such, does not
            guarantee the accuracy, integrity or quality of such content. Users
            acknowledge that by using the Service, they may be exposed to
            materials that are offensive, indecent or objectionable. Under no
            circumstances will CodeFusion be liable in any way for any
            materials, including, but not limited to, for any errors or
            omissions in any materials or any defects or errors in any printing
            or manufacturing, or for any loss or damage of any kind incurred as
            a result of the viewing or use of any materials posted, emailed,
            transmitted or otherwise made available via the Service. ClickDishes
            allows users to suggest restaurants on our App. The suggest address,
            menus and pricing information are found on publicly available
            resources and listed for menu preview purpose only. For restaurant
            owners, claiming your business on our app and joining our platform
            is easy. Email us to get started at hello@codefusion.tech.
          </p>
          <p>
            15. Restrictions on User Content and Use of the Service. CodeFusion
            reserves the right at all times (but will have no obligation) to
            remove or refuse to distribute any User Content and to terminate
            users or reclaim usernames. We also reserve the right to access,
            read, preserve, and disclose any information as we reasonably
            believe is necessary to (i) satisfy any applicable law, regulation,
            legal process or governmental request, (ii) enforce these Terms of
            Use, including investigation of potential violations hereof, (iii)
            detect, prevent, or otherwise address fraud, security or technical
            issues, (iv) respond to user support requests, or (v) protect the
            rights, property or safety of our users and the public. In using the
            Ordering Platform, CodeFusion App and/or Service You shall not: copy
            any content unless expressly permitted to do so herein; upload,
            post, email, transmit or otherwise make available any material that:
            is unlawful, harmful, threatening, abusive, harassing, tortuous,
            defamatory, vulgar, obscene, pornographic, libelous, invasive of
            another's privacy, hateful, or racially or ethnically objectionable,
            encourages criminal behavior, gives rise to civil liability,
            violates any law, or is otherwise objectionable; You do not have a
            right to make available under any law or under a contractual
            relationship; infringes any patent, trademark, trade secret,
            copyright or other proprietary rights of any party (including
            privacy rights); is or contains unsolicited or unauthorized
            advertising, solicitations for business, promotional materials,
            "junk mail," "spam," "chain letters," "pyramid schemes," or any
            other form of solicitation; contains software viruses or any other
            computer code, files or programs designed to interrupt, destroy or
            limit the functionality of any computer software or hardware or
            telecommunications equipment or data or the Ordering Platform or
            that of any users or viewers of the Ordering Platform or that
            compromises a user’s privacy; or contains any falsehoods or
            misrepresentations or create an impression that You know is
            incorrect, misleading, or deceptive, or any material that could
            damage or harm minors in any way; impersonate any person or entity
            or misrepresent their affiliation with a person or entity; forge
            headers or otherwise manipulate identifiers in order to disguise the
            origin of any material transmitted to or through the Ordering
            Platform or impersonate another person or organization; interfere
            with or disrupt the Ordering Platform or servers or networks
            connected to the Ordering Platform, or disobey any requirements,
            procedures, policies or regulations of networks connected to the
            Ordering Platform or probe, scan, or test the vulnerability of any
            system or network or breach or circumvent any security or
            authentication measures; intentionally or unintentionally violate
            any applicable local, state, national or international law or
            regulation; collect or store personal data about other users or
            viewers; license, sell, rent, lease, transfer, assign, distribute,
            host, or otherwise commercially exploit the Ordering Platform or
            CodeFusion App; modify, translate, make derivative works of,
            disassemble, decompile, reverse compile or reverse engineer any part
            of the CodeFusion App or any software provided as part of the
            Ordering Platform, except to the extent the foregoing restrictions
            are expressly prohibited by applicable law; or register for the
            Service on behalf of a group or corporate entity. You also agree not
            to access the Ordering Platform in a manner that utilizes the
            resources of the Ordering Platform more heavily than would be the
            case for an individual person using a conventional web browser.
            Notwithstanding the foregoing, operators of public search engines
            may use spiders or other bots for the purpose of creating publicly
            available searchable indices of the materials on this Ordering
            Platform.
          </p>
          <p>
            16. License of Content. By submitting, posting or displaying User
            Content on or through the Service, you grant us (and our agents) a
            non-exclusive, royalty-free license (with the right to sublicense)
            to use, copy, modify, transmit, display and distribute such User
            Content. CodeFusion will not be responsible or liable for any use of
            User Content in accordance with these Terms. You represent and
            warrant that you have all the rights, power and authority necessary
            to grant the rights granted herein to any User Content that you
            submit.
          </p>
          <p>
            17. End User License. Except for User Content, the CodeFusion App,
            this Ordering Platform, and the information and materials that it
            contains, are the property of CodeFusion and its licensors, and are
            protected from unauthorized copying and dissemination by copyright
            law, trademark law, and other intellectual property laws. Subject to
            the terms of this Agreement, CodeFusion grants you a
            non-transferable, non-exclusive, license to (a) use the Ordering
            Platform for your use, and (b) download, install and use one copy of
            the Application on a mobile device that you own or control for your
            use (the “License”). The CodeFusion App is licensed to you and not
            sold. Nothing in the Terms gives you a right to use the CodeFusion
            names, trademarks, logos, domain names, and other distinctive brand
            features without our prior written consent. Any future release,
            update, upgrade, modification, enhancement or other addition to
            functionality of the Ordering Platform or CodeFusion App shall be
            subject to the terms of these Terms.
          </p>
          <p>
            18. Feedback. If You provide CodeFusion with any suggestions,
            comments or other feedback relating to any aspect of the Ordering
            Platform, CodeFusion App and/or Service ("Feedback"), CodeFusion may
            use such Feedback in the Ordering Platform or in any other
            CodeFusion products or services (collectively, "CodeFusion
            Offerings"). Accordingly, You agree that: (a) CodeFusion is not
            subject to any confidentiality obligations in respect to the
            Feedback, (b) the Feedback is not confidential or proprietary
            information of You or any third party and You have all of the
            necessary rights to disclose the Feedback to CodeFusion, (c)
            CodeFusion (including all of its successors and assigns and any
            successors and assigns of any of the CodeFusion Offerings) may
            freely use, reproduce, publicize, license, distribute, and otherwise
            commercialize Feedback in any CodeFusion Offerings, and (d) You are
            not entitled to receive any compensation or re-imbursement of any
            kind from CodeFusion or any of the other users of the Ordering
            Platform in respect of the Feedback.
          </p>
          <p>
            19. Equipment. You shall be responsible for obtaining access to the
            App and acknowledge that such access may involve third-party fees
            (such as Internet service provider access, data fees or calling
            rates). You shall be solely responsible for any such fees and also
            for obtaining any equipment that is required to access the App. It
            is your responsibility to ascertain whether any information or
            materials downloaded from the App are free of viruses, worms, Trojan
            Horses, or other items of a potentially destructive nature.
          </p>
          <p>
            20. Links & Third-Party Websites. This App (including Restaurant
            Menus and User’s Restaurant ratings) may contain links to other
            websites that are not owned or controlled by CodeFusion. In no event
            shall any reference to any third party, third party product or
            service be construed as an approval or endorsement by CodeFusion of
            that third party, third party product or service. CodeFusion is also
            not responsible for the content of any linked websites. Any
            third-party websites or services accessed from the Ordering Platform
            are subject to the terms and conditions of those websites and or
            services and You are responsible for determining those terms and
            conditions and complying with them. The presence on the App of a
            link to any other website(s) does not imply that CodeFusion endorses
            or accepts any responsibility for the content or use of such
            websites, and You hereby release CodeFusion from all liability
            and/damages that may arise from Your use of such websites or receipt
            of services from any such websites.
          </p>
          <p>
            21. DISCLAIMER OF REPRESENTATIONS, WARRANTIES AND CONDITIONS. THE
            ORDERING PLATFORM, SERVICE, CODEFUSION APP AND ALL MATERIALS
            PROVIDED THEREIN ARE PROVIDED "AS IS." CODEFUSION SPECIFICALLY
            DISCLAIMS ALL REPRESENTATIONS, WARRANTIES AND CONDITIONS, EITHER
            EXPRESS, IMPLIED, STATUTORY, BY USAGE OF TRADE, COURSE OF DEALING OR
            OTHERWISE INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF
            MERCHANTABILITY, NON-INFRINGEMENT, TITLE, SATISFACTORY QUALITY OR
            FITNESS FOR A PARTICULAR PURPOSE. ANY INFORMATION OR MATERIAL
            DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS
            AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE
            FOR ANY ITEMS, PRODUCTS, GOODS OR SERVICES YOU PURCHASE THROUGH THE
            APP, ANYTHING YOU CONSUME FROM AN IN-APP PURCHASE, DAMAGE TO YOUR
            COMPUTER SYSTEM, LOSS OF DATA, OR ANY OTHER LOSS THAT RESULTS FROM
            DOWNLOADING OR USING ANY SUCH MATERIAL. CODEFUSION DOES NOT WARRANT,
            ENDORSE, GUARANTEE, PROVIDE ANY CONDITIONS OR REPRESENTATIONS, OR
            ASSUME ANY RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
            OFFERED BY ANY THIRD PARTY, INCLUDING THE RESTAURANTS, THROUGH THE
            ORDERING PLATFORM OR IN RESPECT TO ANY ORDERING PLATFORM THAT CAN BE
            REACHED FROM A LINK ON THE ORDERING PLATFORM OR FEATURED IN ANY
            BANNER OR OTHER ADVERTISING ON THE ORDERING PLATFORM, AND CODEFUSION
            SHALL NOT BE A PARTY TO ANY TRANSACTION THAT YOU MAY ENTER INTO WITH
            ANY SUCH THIRD PARTY, INCLUDING THE RESTAURANTS, EXCEPT AS EXPRESSLY
            SET FORTH HEREIN. CODEFUSION WILL NOT BE LIABLE FOR ANY TYPE OF
            CONTENT EXCHANGED BY MEANS OF THE SERVICE.
          </p>
          <p>
            22. LIMITATION OF LIABILITY. UNDER NO CIRCUMSTANCES SHALL CODEFUSION
            BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
            EXEMPLARY OR OTHER DAMAGES WHATSOEVER, INCLUDING, WITHOUT
            LIMITATION, ANY DAMAGES THAT RESULT FROM (I) YOUR USE OF OR YOUR
            INABILITY TO USE THIS ORDERING PLATFORM, CODEFUSION APP OR THE
            SERVICE, (II) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS, DATA,
            INFORMATION OR SERVICES, (III) ERRORS, MISTAKES, OR INACCURACIES IN
            THE MATERIALS ON THE ORDERING PLATFORM, (IV) PERSONAL INJURY OR
            PROPERTY DAMAGE OF ANY KIND WHATSOEVER ARISING FROM OR RELATING TO
            YOUR USE OF THE SERVICE INCLUDING WITHOUT LIMITATION FOOD OR ALCOHOL
            RELATED ILLNESSES, ALLERGIC REACTIONS AND ANY ACT OR OMISSION OF THE
            RESTAURANTS, ANY BUGS, VIRUSES, TROJAN HORSES, OR ANY OTHER FILES OR
            DATA THAT MAY BE HARMFUL TO COMPUTER OR COMMUNICATION EQUIPMENT OR
            DATA THAT MAY HAVE BEEN TRANSMITTED TO OR THROUGH THE ORDERING
            PLATFORM OR CODEFUSION APP, OR (V) ANY ERRORS OR OMISSIONS IN ANY
            MATERIAL ON THE ORDERING PLATFORM OR ANY OTHER LOSS OR DAMAGE OF ANY
            KIND ARISING FROM OR RELATING TO YOUR USE OF THE ORDERING PLATFORM.
            THESE LIMITATIONS SHALL APPLY EVEN IF CODEFUSION HAS BEEN ADVISED OF
            THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE
            CONTRARY CONTAINED HEREIN, CLICKDISHES’ LIABILITY TO YOU FOR ANY
            DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT (FOR ANY CAUSE
            WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL
            TIMES BE LIMITED TO THE GREATER OF (A) FIFTY CANADIAN DOLLARS ($50
            CAD) OR (B) AMOUNTS YOU’VE PAID CODEFUSION IN THE MONTH PRECEDING
            THE DATE THE CLAIM AROSE (IF ANY). THE FOREGOING LIMITATIONS SHALL
            APPLY TO THE FULLEST EXTENSION PERMITTED BY LAW IN THE APPLICABLE
            JURISDICTION.
          </p>
          <p>
            23. INDEMNIFICATION. YOU SHALL INDEMNIFY AND HOLD CODEFUSION AND ITS
            SUBSIDIARIES, AFFILIATES, OFFICERS, AGENTS, AND EMPLOYEES, HARMLESS
            FROM ALL CLAIMS, ACTIONS, PROCEEDINGS, DEMANDS, DAMAGES, LOSSES,
            COSTS, AND EXPENSES (INCLUDING REASONABLE ATTORNEYS' FEES), INCURRED
            IN CONNECTION WITH ANY MATERIALS SUBMITTED, POSTED, TRANSMITTED OR
            MADE AVAILABLE BY YOU THROUGH THE SERVICE AND/OR ANY VIOLATION BY
            YOU OF THESE TERMS OF USE.
          </p>
          <p>
            24. Termination. CodeFusion may, under certain circumstances and
            without prior notice, immediately terminate Your ability to access
            the Ordering Platform or portions thereof. Cause for such
            termination shall include, but not be limited to, (a) breaches or
            violations of these Terms of Use or any other agreement that You may
            have with CodeFusion (including, without limitation, non-payment of
            any fees owed in connection with the ordering platform or otherwise
            owed by You to CodeFusion), (b) requests by law enforcement or other
            government agencies, (c) a request by You, (d) discontinuance or
            material modification to the ordering platform (or any part
            thereof), (e) unexpected technical, security or legal issues or
            problems, and/or (f) participation by You, directly or indirectly,
            in fraudulent or illegal activities. Termination of Your access to
            the Ordering Platform may also include removal of some or all of the
            materials uploaded by You to the Ordering Platform. You acknowledge
            and agree that all terminations may be made by CodeFusion in its
            sole discretion and that CodeFusion shall not be liable to You or
            any third-party for any termination of Your access to this Ordering
            Platform or for the removal of any of the materials uploaded by You
            to the Ordering Platform. Any termination of these terms of use by
            CodeFusion shall be in addition to any and all other rights and
            remedies that CodeFusion may have.
          </p>
          <p>
            25. Availability & Updates. CodeFusion may alter, suspend, or
            discontinue this Ordering Platform at any time and for any reason or
            no reason, without notice. The Ordering Platform, Service and/or
            CodeFusion App may be unavailable from time to time due to
            maintenance or malfunction of computer or network equipment or other
            reasons. CodeFusion may periodically add or update the information
            and materials on this Ordering Platform without notice. Security[11]
            [12] . Information sent or received over the Internet is generally
            unsecure and CodeFusion cannot and does not make any representation
            or warranty concerning security of any communication to or from the
            Ordering Platform or any representation or warranty regarding the
            interception by third parties of personal or other informationYou
            are responsible for safeguarding the password that you use to access
            the Service and you are responsible for any activities or actions
            under your password. You agree to keep your password secure.
            CodeFusion will not be liable for any loss or damage arising from
            your failure to comply with these requirements.
          </p>
          <p>
            26. General. This Agreement is governed by the laws of the Province
            of British Columbia and the laws of Canada applicable therein,
            without regard to conflicts of laws. In the event of any dispute
            arising under these terms of use, the parties hereby agree to the
            exclusive jurisdiction of the Courts of the Province of British
            Columbia. These Terms of Use, together with any privacy policy that
            may be published on the Ordering Platform, constitutes the entire
            agreement between the parties relating to the Ordering Platform, the
            App and Service and all related activities. These terms of use shall
            not be modified except in writing signed by both parties or by a new
            posting of these terms of use issued by CodeFusion. If any part of
            these Terms of Use is held to be unlawful, void, or unenforceable,
            that part shall be deemed severed and shall not affect the validity
            and enforceability of the remaining provisions. The failure of
            CodeFusion to exercise or enforce any right or provision under these
            Terms of Use shall not constitute a waiver of such right or
            provision. Any waiver of any right or provision by CodeFusion must
            be in writing and shall only apply to the specific instance
            identified in such writing. You may not assign the terms of use, or
            any rights or licenses granted hereunder, whether voluntarily, by
            operation of law, or otherwise without Clickdishes’ prior written
            consent. If you have any questions about these Terms or if you wish
            to make any complaint or claim with respect to the CodeFusion App or
            Service, please contact us at: hello@codefusion.tech.
          </p>

          <p>Effective Date: 01/09/2018</p>
        </div>
      </div>
    );
  }
}

const styles = {
  container: css({
    display: "flex",
    justifyContent: "center",
    padding: "4rem",
  }),
  content: css({
    width: "100rem",
  }),
};
