import React, { Component } from "react";
import { css } from "emotion";

export default class Privacy extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <h1>Privacy</h1>
          <p>
            CodeFusion Inc. (CodeFusion/“we”/”us”) is committed to protecting
            your privacy. Our ordering platform consists of web and mobile apps
            ("the Ordering Platform"). This Privacy Policy describes how we
            collect, store, use and distribute information about users in the
            Ordering Platform.
          </p>
          <p>
            Consent: By using the Ordering Platform, you consent to the use of
            your Personal Information as described in this Privacy Policy. Your
            Personal Information will not be used for any other purpose without
            your consent except as set forth in this Privacy Policy. We will not
            actively collect Personal Information for the purpose of sale or
            marketing in a way that specifically identifies the individual. You
            may withdraw consent to our processing of your Personal Information
            at any time. However, withdrawing consent may result in your
            inability to continue using the Ordering Platform.
          </p>
          <p>
            Collection of Information: We aim to collect, use and disclose only
            such information as is required to enable us to manage your account,
            to provide you services, to maintain our customer lists, to respond
            to your inquiries, for identification and authentication purposes,
            and for service improvement. We will maintain the confidentiality of
            any contact information you provide to us when signing up for the
            Ordering Platform (subject to the exclusions and disclosures we have
            listed below), unless you agree that we may disclose it to third
            parties.
          </p>
          <p>
            Two types of information may be collected through the Ordering
            Platform: Personal Information and Non-Personal Information. This
            Privacy Policy does not extend to the collection, use or disclosure
            of the following information which is currently not limited by
            applicable privacy laws: (a) information that is publicly available,
            such as names, addresses, phone numbers and email addresses when
            listed in a directory or made available through directory
            assistance; or (b) Non-Personal Information (as defined further
            below).
          </p>
          <p>
            "Personal Information" is personally identifiable information, such
            as your name, address, email address, credit card information, birth
            date and gender. It is always your choice whether or not to provide
            Personal Information, but if you choose not to provide it, you may
            not be able to register with the Ordering Platform. In other
            instances, your choice not to provide Personal Information may mean
            that you will not be able to use certain features of the Ordering
            Platform. We may collect Personal Information through registration
            processes; communications with you; information downloads; service
            purchases; user support; and surveys.
          </p>
          <p>
            "Non-Personal Information" is information that is anonymous, such as
            an Internet Protocol Address (IP Address), the domain used to access
            the Ordering Platform, and the type of browser or operating system
            being used. Aggregate information, such as demographic statistics of
            our users (e.g. average age or geographical allocation of our
            users), number of visitors, what pages users access or visit, and
            average time spent on the Ordering Platform is not considered
            Personal Information. Similarly, business contact information such
            as the name, title, business address, or phone number of a business
            or professional person or an employee of an organization is not
            considered Personal Information.
          </p>
          <p>
            Although the use of Non-Personal Information collected, used or
            disclosed through the Internet as described here is not restricted
            (and to the extent that such is the case, the obligations under this
            Privacy Policy do not apply to such information), we describe below
            how we use such information for the sake of transparency with
            respect to the operation of the Ordering Platform. Non-Personal
            Information is collected in the course of operating the Ordering
            Platform. For example, our web servers may automatically collect
            Non-Personal Information that is provided by your browser or stored
            in a cookie when you choose to visit the Ordering Platform.
          </p>
          <p>We collect information for the following purposes:</p>
          <p>
            Registration: In order to use the Ordering Platform, you must
            provide certain Personal Information to us such as your phone
            number, and email address. In general, other than your email and
            phone number, none of your account registration information will be
            shared with any other users of the Ordering Platform, except as set
            forth below.
          </p>
          <p>
            Business Information Sharing: When you place an order, your email
            and phone number is shared with the Business. In order for the
            Business to identify you when your pickup order is ready, we will
            share your phone number with the Business.
          </p>
          <p>
            Payment Information: In order to use the Ordering Platform, you will
            be required to pay for purchases by way of valid credit card. Our
            payment processor, Stripe, is responsible for processing your
            payment information, and except for the last four digits of your
            credit card number, we do not collect, hold, store or process any
            payment information of our Users.
          </p>
          <p>
            Transactional Notifications: We provide notifications for certain
            activities relating to your use of the Ordering Platform despite
            your indicated email preferences, for example we may send you
            receipts, order confirmations, and notices of any updates to our
            Privacy Policy.
          </p>
          <p>
            Marketing Communications: If you opt-in to receive marketing
            communications from us, we will keep you up to date on our products
            and services. We use a third party email marketing service provider
            to assist us with our marketing communications, and accordingly we
            may provide certain personal information such as your email and
            phone number to our third party service provider. You may withdraw
            your consent to receiving marketing communications from us at any
            time by following the opt-out instructions in each communication.
          </p>
          <p>
            Statistics: We also collect statistics about use of the Ordering
            Platform. Aggregate statistics that does not personally identify an
            individual may be made available to other members or third parties.
          </p>
          <p>
            System Logs & Cookies: Cookies are used to track content usage and
            traffic on the Ordering Platform. A cookie is a feature of your web
            browser that consists of a text file that is placed on your hard
            disk by a web server. The Ordering Platform uses cookies to help it
            compile aggregate statistics about usage of the Ordering Platform,
            such as how many users visit the Ordering Platform, how long users
            spend viewing the Ordering Platform, and what pages are viewed most
            often. This information is used to improve the content of the
            Ordering Platform. You can set your browser to notify you when you
            are sent a cookie. This gives you the chance to decide whether or
            not to accept it. If you disable cookies, you may not be able to
            take advantage of all the features of the Ordering Platform. We do
            not link any of the information we use in cookies to any personally
            identifiable information submitted by you when you are on the
            Ordering Platform.
          </p>
          <p>
            Disclosures & Transfers: We have put in place contractual and other
            organizational safeguards with our agents (see further below) to
            ensure a proper level of protection of your Personal Information
            (see further Security below). In addition to those measures, we will
            not disclose or transfer your Personal Information to third parties
            without your permission, except as specified in this Privacy Policy
            (see further Important Exceptions below).
          </p>
          <p>
            We share Personal Information in respect of the Ordering Platform
            only with our agents and partners such as payment processors, cloud
            service providers, third party marketing service providers such as
            SendGrid, and website hosting. Additionally, our Servers are located
            the United States and your Personal Information may be available to
            the United States government or its agencies under a lawful order,
            irrespective of the safeguards we have put in place for the
            protection of your Personal Information.
          </p>
          <p>
            From time to time, we may employ third parties to help us improve
            the Ordering Platform. These third parties may have limited access
            to databases of user information solely for the purpose of helping
            us to improve the Ordering Platform and they will be subject to
            contractual restrictions prohibiting them from using the information
            about our members for any other purpose.
          </p>
          <p>
            Important Exceptions: We may disclose your Personal Information to
            third parties without your consent if we have reason to believe that
            disclosing this information is necessary to identify, contact or
            bring legal action against someone who may be causing injury to or
            interference with (either intentionally or unintentionally) our
            rights or property, other users of the Ordering Platform, or anyone
            else. We may disclose Personal Information when we believe in good
            faith that such disclosure is required by and in accordance with the
            law.
          </p>
          <p>
            We may also disclose your Personal Information in connection with a
            corporate re-organization, a merger or amalgamation with another
            entity, a sale of all or a substantial portion of our assets or
            stock, including any due diligence exercise carried out in relation
            to the same, provided that the information disclosed continues to be
            used for the purposes permitted by this Privacy Policy by the entity
            acquiring the information.
          </p>
          <p>
            Security: The security of your Personal Information is important to
            us. We use commercially reasonable efforts to store and maintain
            your Personal Information in a secure environment. We take
            technical, contractual, administrative, and physical security steps
            designed to protect the Personal Information that you provide to us.
            We have implemented procedures designed to limit the dissemination
            of your Personal Information to only such designated staff as are
            reasonably necessary to carry out the stated purposes we have
            communicated to you. In certain areas, such as when you provide
            credit card or other payment information to us, the App works to
            help protect the security of your credit card and other payment
            information during transmission by using industry standard Secure
            Sockets Layer (SSL) encryption technology.
          </p>
          <p>
            You are also responsible for helping to protect the security of your
            Personal Information. For instance, never give out your email or
            password for the Ordering Platform to third parties.
          </p>
          <p>
            Retention: We will keep your Personal Information for as long as it
            remains necessary for the Ordering Platform or as required by law,
            which may extend beyond the termination of our relationship with
            you. We may retain certain data as necessary to prevent fraud or
            future abuse, or for legitimate business purposes, such as analysis
            of aggregated data, account recovery, or if required by law. All
            retained Personal Information will remain subject to the terms of
            this Privacy Policy. If you request that your Personal Information
            be removed from our databases, it may not be possible to completely
            delete it due to technical and legal constraints.
          </p>
          <p>
            Amendment of this Policy: We reserve the right to change this
            Privacy Policy at any time. If we decide to change this Privacy
            Policy in the future, we will post an appropriate notice at
            codefusion.tech/privacy. Any change to this Privacy Policy will
            become effective on the date the change is posted. Unless stated
            otherwise, our current Privacy Policy applies to all Personal
            Information that we have about you. The date on which the latest
            update was made is indicated at the bottom of this document. We
            recommend that you print a copy of this Privacy Policy for your
            reference and revisit this policy from time to time to ensure you
            are aware of any changes. Your continued use of the Ordering
            Platform signifies your acceptance of any changes.
          </p>
          <p>
            Access and Accuracy: You have the right to access the Personal
            Information we hold about you in order to verify how we use it. Upon
            receipt of a written request, we will provide you a copy of your
            Personal Information although in some circumstances, we may not be
            able to make all information available to you. In such
            circumstances, we will provide reasons for the denial. We will
            endeavor to deal with all requests for access and modifications in a
            timely manner.
          </p>
          <p>
            We will make every reasonable effort to keep your Personal
            Information accurate and up-to-date, and we will provide you with
            mechanisms to update or delete to your Personal Information. Having
            accurate Personal Information about you enables us to give you the
            best possible service.
          </p>
          <p>
            Contact Us: If you have any questions, comments or suggestions,
            please contact us at: hello@codefusion.tech.
          </p>
          <p>Effective Date: 01/09/2018</p>
        </div>
      </div>
    );
  }
}

const styles = {
  container: css({
    display: "flex",
    justifyContent: "center",
    padding: "4rem",
  }),
  content: css({
    width: "100rem",
  }),
};
