import React, { Component } from "react";
import Routes from "./routes";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheckCircle,
  faChevronRight,
  faEdit,
  faMapMarker,
  faMinusCircle,
  faPlusCircle,
  faShoppingCart,
  faSignInAlt,
  faSpinner,
  faTimesCircle,
  faUser,
  faUtensils,
  faStar
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faCheckCircle,
  faChevronRight,
  faEdit,
  faMapMarker,
  faMinusCircle,
  faPlusCircle,
  faShoppingCart,
  faSignInAlt,
  faSpinner,
  faTimesCircle,
  faUser,
  faUtensils,
  faStar
);

export default class App extends Component {
  render() {
    return <Routes />;
  }
}
