import React, { Component } from "react";
import { css } from "emotion";
import { CFView, CFText } from "components";
import {
  features1,
  mobileFeatures,
  features2,
  tabletFeatures,
  featuresbg
} from "images";
import { MEDIA_QUERY } from "styles";

export default class Features extends Component {
  render() {
    return (
      <CFView className={styles.container}>
        <CFText tech white h1 bold>
          Web & Mobile App
        </CFText>
        <div className={styles.mobileContainer}>
          <img
            src={features1}
            alt="Mobile Features"
            className={styles.features1}
          />
          <img
            src={mobileFeatures}
            alt="Mobile Features"
            data-aos="zoom-in"
            className={styles.mobile}
          />
        </div>
        <CFText tech white h1 bold>
          Management Tablet
        </CFText>
        <CFView className={styles.tabletContainer}>
          <img
            src={tabletFeatures}
            alt="Tablet Features"
            data-aos="zoom-in"
            className={styles.tablet}
          />
          <CFView w="4rem" />
          <img
            src={features2}
            alt="Tablet Features"
            className={styles.features2}
          />
        </CFView>
      </CFView>
    );
  }
}

const styles = {
  container: css({
    marginTop: "-10rem",
    marginBottom: "-10rem",
    padding: "14rem 4rem 12rem 4rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: `url(${featuresbg}) no-repeat`,
    backgroundSize: "100% 100%",
    width: "100%",
    zIndex: 99,
    [MEDIA_QUERY.LG]: {
      padding: "16rem 2rem 16rem 2rem",
      justifyContent: "flex-start",
      textAlign: "center",
      backgroundSize: "auto 100%",
      backgroundPosition: "center"
    }
  }),
  mobileContainer: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginBottom: "4rem",
    maxWidth: "140rem",
    [MEDIA_QUERY.LG]: {
      flexDirection: "column-reverse",
      textAlign: "center"
    }
  }),
  tabletContainer: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxWidth: "140rem",
    [MEDIA_QUERY.LG]: {
      flexDirection: "column",
      textAlign: "center"
    }
  }),
  features1: css({
    width: "42rem",
    objectFit: "contain",
    [MEDIA_QUERY.LG]: {
      marginTop: "-2rem",
      width: "100%"
    }
  }),
  mobile: css({
    width: "64rem",
    marginLeft: "4rem",
    objectFit: "contain",
    [MEDIA_QUERY.LG]: {
      marginLeft: 0,
      height: "auto",
      width: "90%"
    },
    [MEDIA_QUERY.SM]: {
      marginBottom: "2rem"
    }
  }),
  features2: css({
    width: "42rem",
    objectFit: "contain",
    [MEDIA_QUERY.LG]: {
      marginTop: "-2rem",
      width: "100%"
    }
  }),
  tablet: css({
    width: "54rem",
    marginRight: "4rem",
    objectFit: "contain",
    [MEDIA_QUERY.LG]: {
      marginRight: 0,
      height: "auto",
      width: "90%"
    }
  })
};
