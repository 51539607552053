import React, { Component } from "react";
import { css } from "emotion";
import { CFText } from "components";
import { hero } from "images";
import { MEDIA_QUERY } from "styles";
import Particles from "react-particles-js";

export default class Hero extends Component {
  render() {
    return (
      <div className={styles.hero}>
        <Particles
          className={styles.particles}
          params={{
            particles: {
              number: { value: 40, density: { enable: true, value_area: 800 } },
              color: { value: "#fff" },
              shape: {
                type: "circle",
                stroke: { width: 0, color: "#000000" },
                polygon: { nb_sides: 5 }
              },
              opacity: {
                value: 1,
                random: false,
                anim: {
                  enable: false,
                  speed: 1,
                  opacity_min: 0.1,
                  sync: false
                }
              },
              size: {
                value: 4,
                random: true,
                anim: { enable: false, speed: 40, size_min: 0.1, sync: false }
              },
              line_linked: {
                enable: true,
                distance: 150,
                color: "#36B7EE",
                opacity: 1,
                width: 2
              },
              move: {
                enable: true,
                speed: 4,
                direction: "none",
                random: false,
                straight: false,
                out_mode: "out",
                bounce: false,
                attract: { enable: false, rotateX: 600, rotateY: 1200 }
              }
            },
            interactivity: {
              detect_on: "canvas",
              events: {
                onhover: { enable: true, mode: "repulse" },
                onclick: { enable: true, mode: "push" },
                resize: true
              },
              modes: {
                grab: { distance: 400, line_linked: { opacity: 1 } },
                bubble: {
                  distance: 400,
                  size: 40,
                  duration: 2,
                  opacity: 8,
                  speed: 3
                },
                repulse: { distance: 200, duration: 0.4 },
                push: { particles_nb: 4 },
                remove: { particles_nb: 2 }
              }
            },
            retina_detect: true
          }}
        />
        <div className={styles.mask} />
        <div className={styles.content}>
          <CFText tech white center className={styles.heading}>
            Cutting-Edge Online Ordering
          </CFText>
          <CFText content h1 white center>
            Start selling online & grow your business.
          </CFText>
        </div>
      </div>
    );
  }
}

const styles = {
  hero: css({
    minHeight: "calc(100vh - 8rem)",
    width: "100%",
    position: "relative",
    background: `url(${hero}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundAttachment: "fixed",
    [MEDIA_QUERY.MD]: {
      backgroundAttachment: "scroll"
    }
  }),
  mask: css({
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(0,0,0,.5)"
  }),
  content: css({
    position: "absolute",
    width: "100%",
    height: "50%",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [MEDIA_QUERY.MD]: {
      padding: "2rem",
      top: "50%",
      lineHeight: 1
    }
  }),
  heading: css({
    fontSize: 70,
    [MEDIA_QUERY.MD]: {
      fontSize: 50,
      marginBottom: "4rem"
    }
  }),
  particles: css({
    height: "100%",
    width: "100%",
    position: "absolute"
  })
};
