import React, { Component } from "react";
import { css } from "emotion";
import { PrimaryButton } from "components";
import { cflogo, footer } from "images";
import { MEDIA_QUERY } from "styles";

export default class PageHeader extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <img src={cflogo} alt="cflogo" />
          <a href="mailto:hello@codefusion.tech">
            <PrimaryButton label="REQUEST DEMO" className={styles.button} />
          </a>
        </div>
      </div>
    );
  }
}

const styles = {
  container: css({
    width: "100%",
    height: "8rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: `url(${footer}) no-repeat`,
    backgroundSize: "cover",
    boxShadow: "0.4rem 0.4rem 0.4rem rgba(0,0,0,0.6)",
    position: "relative",
    zIndex: 1
  }),
  innerContainer: css({
    width: "90%",
    maxWidth: "110rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [MEDIA_QUERY.MD]: {
      justifyContent: "center"
    }
  }),
  button: css({
    [MEDIA_QUERY.MD]: {
      display: "none"
    }
  })
};
