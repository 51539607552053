export const COLOR = {
  primary: "#1E1E1E",
  secondary: "blue",
  white: "#fff",
  black: "#1E1E1E",
  disabled: "grey",
  grey: "#4A4A4A",
  red: "#D0021B",
  green: "#10ac84"
};

export const SCREEN = {
  XS: 375,
  SM: 576,
  MD: 767,
  LG: 992,
  XL: 1200
};

export const MEDIA_QUERY = {
  XS: `@media (max-width: ${SCREEN.XS}px)`,
  SM: `@media (max-width: ${SCREEN.SM}px)`,
  MD: `@media (max-width: ${SCREEN.MD}px)`,
  LG: `@media (max-width: ${SCREEN.LG}px)`,
  XL: `@media (max-width: ${SCREEN.XL}px)`
};
