export const cflogo =
  "https://res.cloudinary.com/codefusiontech/image/upload/cflanding/cflogo.png";
export const cflogo2 =
  "https://res.cloudinary.com/codefusiontech/image/upload/cflanding/cflogo2.png";
export const cfvideo =
  "https://res.cloudinary.com/codefusiontech/video/upload/v1550312236/cflanding/cfvideo.mov";
export const ebisulogo =
  "https://res.cloudinary.com/codefusiontech/image/upload/cflanding/ebisulogo.png";
export const footer =
  "https://res.cloudinary.com/codefusiontech/image/upload/cflanding/footer.png";
export const hero =
  "https://res.cloudinary.com/codefusiontech/image/upload/v1564782281/cflanding/hero.png";
export const hyacksushi =
  "https://res.cloudinary.com/codefusiontech/image/upload/v1554682529/cflanding/hyack.png";
export const hyacklogo =
  "https://res.cloudinary.com/codefusiontech/image/upload/cflanding/hyacklogo.png";
export const manage =
  "https://res.cloudinary.com/codefusiontech/image/upload/cflanding/manage.png";
export const mobile =
  "https://res.cloudinary.com/codefusiontech/image/upload/cflanding/mobile.png";
export const quote =
  "https://res.cloudinary.com/codefusiontech/image/upload/cflanding/quote.png";
export const technology =
  "https://res.cloudinary.com/codefusiontech/image/upload/v1550473246/cflanding/technology.png";
export const web =
  "https://res.cloudinary.com/codefusiontech/image/upload/cflanding/web.png";
export const valueprop1 =
  "https://res.cloudinary.com/codefusiontech/image/upload/v1554660194/cflanding/valueprop1.png";
export const valueprop2 =
  "https://res.cloudinary.com/codefusiontech/image/upload/v1554660197/cflanding/valueprop2.png";
export const valueprop3 =
  "https://res.cloudinary.com/codefusiontech/image/upload/v1554660201/cflanding/valueprop3.png";
export const valueprop4 =
  "https://res.cloudinary.com/codefusiontech/image/upload/cflanding/valueprop4.png";
export const valueprop5 =
  "https://res.cloudinary.com/codefusiontech/image/upload/cflanding/valueprop5.png";
export const valueprop6 =
  "https://res.cloudinary.com/codefusiontech/image/upload/cflanding/valueprop6.png";
export const hyackmobile =
  "https://res.cloudinary.com/codefusiontech/image/upload/cflanding/hyackmobile.gif";
export const ebisumobile =
  "https://res.cloudinary.com/codefusiontech/image/upload/v1564782834/cflanding/ebisumobile.gif";
export const hatopopomobile =
  "https://res.cloudinary.com/codefusiontech/image/upload/cflanding/hatopopomobile.gif";
export const ryuumobile =
  "https://res.cloudinary.com/codefusiontech/image/upload/cflanding/ryuumobile.gif";
export const hatopopo =
  "https://res.cloudinary.com/codefusiontech/image/upload/v1554682444/cflanding/hatopopo.png";
export const marblebg =
  "https://res.cloudinary.com/codefusiontech/image/upload/cflanding/marblebg.jpg";
export const mobileFeatures =
  "https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1564782280/cflanding/mobileFeatures.png";
export const tabletFeatures =
  "https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1564782280/cflanding/tabletFeatures.png";
export const techbg =
  "https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1564782294/cflanding/techbg.png";
export const featuresbg =
  "https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1554676729/cflanding/featuresbg2.png";
export const features1 =
  "https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1554660061/cflanding/features1.png";
export const features2 =
  "https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1554660065/cflanding/features2.png";
export const partnersbg =
  "https://res.cloudinary.com/codefusiontech/image/upload/v1564782281/cflanding/partnersbg.png";
export { default as applause } from "./applause.png";
export { default as chihouse } from "./chihouse.png";
export { default as ebisu } from "./ebisu.png";
export { default as ginza } from "./ginza.png";
export { default as hanayuki } from "./hanayuki.png";
export { default as hokepoke } from "./hokepoke.png";
export { default as hyack } from "./hyack.png";
export { default as jamesonhastings } from "./jamesonhastings.png";
export { default as kamei } from "./kamei.png";
export { default as kaori } from "./kaori.png";
export { default as kiku } from "./kiku.png";
export { default as kitstaya } from "./kitstaya.png";
export { default as meadowsushi } from "./meadowsushi.png";
export { default as mrho } from "./mrho.png";
export { default as onemoreszechuan } from "./onemoreszechuan.png";
export { default as onon } from "./onon.png";
export { default as ryuu } from "./ryuu.png";
export { default as sakemaki } from "./sakemaki.png";
export { default as shaolin } from "./shaolin.png";
export { default as tacofactory } from "./tacofactory.png";
export { default as tomsushi } from "./tomsushi.png";
